import { StudioNodeData } from '@common/studio-types';
import React from 'react';
import { Edge, Node, ReactFlowInstance, useReactFlow } from 'reactflow';
import { StudioFlow } from './StudioFlow';

type Props = {
  save: (instance: ReactFlowInstance) => Promise<void>;
  showControls?: boolean;
  nodes: Node<StudioNodeData>[];
  edges: Edge[];
};

export const EpisodeStudioFlow: React.FC<Props> = (props) => {
  const instance = useReactFlow();

  return (
    <StudioFlow
      showControls
      onChange={() => props.save(instance)}
      initialNodes={props.nodes}
      initialEdges={props.edges}
    />
  );
};
