import { dimensions } from '@maestro/styles';
import React from 'react';
import { ReactFlowProvider } from 'reactflow';
import styled from 'styled-components';
import { EditEpisodeModal } from './components/episode/EditEpisode/EditEpisodeModal';
import { PublishEpisodeModal } from './components/episode/PublishEpisode/PublishEpisodeModal';
import { EditSeriesModal } from './components/series/EditSeries/EditSeriesModal';
import { EpisodeStudioFlow } from './EpisodeStudioFlow';
import { useStudioFlow } from './hooks/useStudioFlow';
import { StudioTopBar } from './topBar/StudioTopBar';

const InternalStudio = () => {
  const { flowState, save } = useStudioFlow();

  if (flowState) {
    return (
      <Container>
        <StudioTopBar />

        <ReactFlowProvider>
          <EpisodeStudioFlow
            save={save}
            showControls
            nodes={flowState.nodes}
            edges={flowState.edges}
          />
        </ReactFlowProvider>
        <PublishEpisodeModal />
        <EditEpisodeModal />
        <EditSeriesModal />
      </Container>
    );
  }
};

export const Studio: React.FC = () => {
  return <InternalStudio />;
};

const Container = styled.div`
  position: absolute;
  top: ${dimensions.size0};
  left: ${dimensions.size0};
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100dvh;
`;
