import { Checkbox } from '@chakra-ui/react';
import {
  NarratorTextNodeData,
  SingleSelectNodeData,
  StudioNodeData,
} from '@common/studio-types';
import { Icon } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { BaseStudioNode } from '../nodes/BaseStudioNode';
import { InterpolatedText } from './value/InterpolatedText';

type Props = {
  id: string;
  old: StudioNodeData;
  updated: StudioNodeData;
  selected: boolean;
  onSelectionChange: (selected: boolean) => void;
};

const NarratorText: React.FC<NarratorTextNodeData> = (props) => {
  return (
    <BaseStudioNode hideControls title="Narrator response" nodeId={props.id}>
      <Text>
        <InterpolatedText text={props.text} />
      </Text>
    </BaseStudioNode>
  );
};

const SingleSelect: React.FC<SingleSelectNodeData> = (props) => {
  return (
    <BaseStudioNode hideControls title="Player choice" nodeId={props.id}>
      <Text>
        <InterpolatedText text={props.prompt} />
      </Text>
      <ChoicesContainer>
        {props.options.map((option) => (
          <SingleSelectItem key={option.id}>
            <InterpolatedText text={option.text} />
          </SingleSelectItem>
        ))}
      </ChoicesContainer>
    </BaseStudioNode>
  );
};

export const NodeDiff: React.FC<Props> = (props) => {
  const checkbox = (
    <Checkbox
      isChecked={props.selected}
      iconColor="white"
      colorScheme="primary"
      size="lg"
      onChange={(e) => props.onSelectionChange(e.target.checked)}
    />
  );

  if (
    props.old.type === 'NarratorText' &&
    props.updated.type === 'NarratorText'
  ) {
    return (
      <Container>
        {checkbox}
        <NarratorText {...props.old} />
        <Icon name="arrow-right" size={rawDimensions.size32} />
        <NarratorText {...props.updated} />
      </Container>
    );
  }

  if (
    props.old.type === 'SingleSelect' &&
    props.updated.type === 'SingleSelect'
  ) {
    return (
      <Container>
        {checkbox}
        <SingleSelect {...props.old} />
        <Icon name="arrow-right" size={rawDimensions.size32} />
        <SingleSelect {...props.updated} />
      </Container>
    );
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${dimensions.size8};
`;

const Text = styled.div`
  white-space: pre-line;
`;

const ChoicesContainer = styled.div`
  display: flex;
  margin-top: ${dimensions.size8};
  flex-direction: column;
  gap: ${dimensions.size8};
`;

const SingleSelectItem = styled.div`
  ${textStyles.body.b12m}
  position: relative;
  padding: ${dimensions.size16};
  color: ${({ theme }) => theme.colors.text.header};
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;
