import type { ConditionCheckNodeData } from '@common/studio-types';
import { dimensions, textStyles } from '@maestro/styles';
import React, { memo } from 'react';
import { NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import { BaseStudioNode } from '../BaseStudioNode';
import { Handle } from '../Handle';
import { Condition } from './Condition';

type Props = NodeProps<ConditionCheckNodeData>;

const Node: React.FC<Props> = ({ id, data, selected }) => {
  return (
    <BaseStudioNode nodeId={id} title="Condition" selected={selected}>
      <Handle
        nodeId={data.id}
        type="target"
        position={Position.Top}
        id="target-top"
      />

      <StyledCondition data={data} />

      <ConditionCheckNodeContainer>
        <ConditionCheckResultContainer $isTrue>
          True
          <Handle
            nodeId={data.id}
            label="True"
            type="source"
            position={Position.Bottom}
            id={`${data.id}-true`}
          />
        </ConditionCheckResultContainer>
        <ConditionCheckResultContainer $isTrue={false}>
          False
          <Handle
            nodeId={data.id}
            label="False"
            type="source"
            position={Position.Bottom}
            id={`${data.id}-false`}
          />
        </ConditionCheckResultContainer>
      </ConditionCheckNodeContainer>
    </BaseStudioNode>
  );
};

export const ConditionCheckNode = memo(Node);

const ConditionCheckNodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  justify-content: space-evenly;
  margin-bottom: -${dimensions.size16};
`;

const StyledCondition = styled(Condition)`
  padding-bottom: ${dimensions.size20};
`;

const ConditionCheckResultContainer = styled.div<{ $isTrue: boolean }>`
  ${textStyles.body.b12sb}
  position: relative;
  width: ${dimensions.size72};
  padding: ${dimensions.size4} ${dimensions.size4} ${dimensions.size6}
    ${dimensions.size4};
  color: ${({ theme, $isTrue }) =>
    $isTrue ? theme.colors.text.accent : theme.colors.base.red['900']};
  text-align: center;
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size4} ${dimensions.size4} ${dimensions.size0}
    ${dimensions.size0};
`;
