import { Badge } from '@chakra-ui/react';
import { dimensions } from '@maestro/styles';
import React, { memo } from 'react';
import { NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import { BaseStudioNode } from '../BaseStudioNode';
import { Handle } from '../Handle';

const Node: React.FC<NodeProps> = ({ id, selected }) => {
  return (
    <BaseStudioNode
      nodeId={id}
      title="Character creation"
      selected={selected}
      disableEditing
    >
      <Container>
        <Text>Attributes:</Text>
        <Badge variant="capitalized">Strength</Badge>
        <Badge variant="capitalized">Agility</Badge>
        <Badge variant="capitalized">Willpower</Badge>
        <Badge variant="capitalized">Creativity</Badge>
        <Badge variant="capitalized">Charisma</Badge>
        <Badge variant="capitalized">Agreeableness</Badge>
        <Badge variant="capitalized">Intelligence</Badge>
      </Container>

      <Handle
        nodeId={id}
        type="target"
        position={Position.Top}
        id="target-top"
      />
      <Handle
        nodeId={id}
        type="source"
        position={Position.Bottom}
        id="source-bottom"
      />
    </BaseStudioNode>
  );
};

export const CharacterCreationNode = memo(Node);

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${dimensions.size8};
  align-items: center;
  margin-bottom: ${dimensions.size16};
`;

const Text = styled.div`
  display: inline-block;
  margin-right: ${dimensions.size8};
`;
