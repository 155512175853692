import { VersionSkewBoundary } from '@maestro/config';
import { FeatureFlagsProvider } from '@maestro/feature-flags/src/FeatureFlagsProvider';
import { PropsWithChildren, Children } from 'react';

/**
 * Simple wrapper component to make the diff for top-level providers easier to view
 */
export const AppProviders: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <FeatureFlagsProvider>
      <VersionSkewBoundary>
        {Children.map(children, (child) => child)}
      </VersionSkewBoundary>
    </FeatureFlagsProvider>
  );
};
