import {
  StatBlockData,
  StudioNodeData,
  StudioNodeType,
} from '@common/studio-types';
import { GenerateImageProvider } from '@common/studio-types/generateImage';
import { useMaestroToast } from '@maestro/components';
import { env } from '@maestro/env';

export type SingleSelectResponse = {
  prompt: string;
  choices: string[];
};

export type NarratorResponse = {
  text: string;
};

export type StatBlockResponse = StatBlockData;

export type GenerateNodeResponse =
  | SingleSelectResponse
  | NarratorResponse
  | StatBlockResponse;

const aiSupportedNodes = [
  StudioNodeType.SingleSelect,
  StudioNodeType.NarratorText,
  StudioNodeType.StatBlock,
  StudioNodeType.Image,
];

export const shouldShowGenerateWithAi = (type: StudioNodeType) =>
  aiSupportedNodes.includes(type);

export const useGenerateNodeWithAi = () => {
  const isNarratorResponse = (
    response: GenerateNodeResponse,
  ): response is NarratorResponse => {
    return (response as NarratorResponse).text !== undefined;
  };

  const isSingleSelectResponse = (
    response: GenerateNodeResponse,
  ): response is SingleSelectResponse => {
    return (response as SingleSelectResponse).choices !== undefined;
  };

  const isStatBlockResponse = (
    response: GenerateNodeResponse,
  ): response is StatBlockResponse => {
    return (response as StatBlockResponse).sections !== undefined;
  };

  const convertStudioNodeTypeToOpenAiType = (type: StudioNodeType) => {
    switch (type) {
      case StudioNodeType.NarratorText:
        return 'narrator-response';
      case StudioNodeType.SingleSelect:
        return 'player-choice';
      case StudioNodeType.StatBlock:
        return 'stat-block';
      default:
        throw new Error('Invalid node type');
    }
  };

  const toast = useMaestroToast();

  const generate = async (
    node: StudioNodeData,
    prompt: string,
    nodesContext?: string,
  ) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, type, ...restOfNode } = node;
      const openAiType = convertStudioNodeTypeToOpenAiType(type);
      const body = JSON.stringify({
        type: openAiType,
        prompt,
        node: restOfNode,
        nodesContext,
      });
      const response = await fetch(
        `${env.VITE_MAESTRO_API_URL}/studio/generate-node`,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body,
        },
      );

      if (!response.ok) {
        toast({
          status: 'warning',
          title: 'AI Generation failed',
        });

        throw new Error('Failed to generate node with ai');
      }

      return await response.json();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const generateImage = async (input: {
    negativePrompt?: string;
    prompt: string;
    nodeId: string;
    uniqueness: string;
    sourceImageUrl?: string;
    provider?: GenerateImageProvider;
  }) => {
    try {
      const body = JSON.stringify({
        ...input,
        provider: input?.provider ?? 'fireworks',
      });

      const response = await fetch(
        `${env.VITE_MAESTRO_API_URL}/studio/generate-image`,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body,
        },
      );

      if (!response.ok) {
        toast({
          status: 'warning',
          title: 'AI image generation failed',
        });

        throw new Error('Failed to generate an image with ai');
      }

      const { imageUrl } = await response.json();

      if (!imageUrl) {
        throw Error(
          'Invalid generate image with ai response, imageUrl is missing!',
        );
      }

      return { imageUrl };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      toast({
        status: 'warning',
        title: 'AI image generation request failed',
      });
    }
  };

  return {
    generate,
    isNarratorResponse,
    isSingleSelectResponse,
    isStatBlockResponse,
    generateImage,
  };
};
