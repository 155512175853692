import { Switch } from '@chakra-ui/react';
import { StudioNodeData, StudioNodeType } from '@common/studio-types';
import { Field, RadioButtons, Textarea } from '@maestro/components';
import React from 'react';
import { Hint } from '../Hint';
import { ImageProviderSelectionField } from './ImageProviderSelectionField';
import { uniquenessOptions } from './shared';

type Props = {
  onStudioNodeDataChange: (studioNode: StudioNodeData) => void;
  studioNodeData: StudioNodeData;
};

export const GenerateImageWithAiFields: React.FC<Props> = (props) => {
  const { studioNodeData } = props;

  const isStatBlock = studioNodeData.type === StudioNodeType.StatBlock;
  const isImageNode = studioNodeData.type === StudioNodeType.Image;

  let showUniqueOptions = false;

  if (isStatBlock) {
    showUniqueOptions = !!studioNodeData.image?.url;
  }

  if (isImageNode) {
    showUniqueOptions = !!studioNodeData?.imageUrl;
  }

  if (studioNodeData.generateImage?.provider === 'falFluxPro') {
    showUniqueOptions = false;
  }

  const shouldGenerateImageSwitch = (
    <Field label="Generate Image">
      <Switch
        size="lg"
        isChecked={!!studioNodeData.generateImage}
        onChange={(e) =>
          props.onStudioNodeDataChange({
            ...studioNodeData,
            generateImage: e.target.checked ? {} : undefined,
          })
        }
      />
    </Field>
  );

  if (isStatBlock && !studioNodeData.generateImage) {
    return shouldGenerateImageSwitch;
  }

  return (
    <>
      <ImageProviderSelectionField
        label="AI Image Provider"
        hint="Pick an ai image provider to generate the image"
        value={studioNodeData.generateImage?.provider}
        onChange={(selection) =>
          props.onStudioNodeDataChange({
            ...studioNodeData!,
            generateImage: {
              ...(studioNodeData.generateImage ?? {}),
              provider: selection,
            },
          })
        }
      />
      <Field label="Image Prompt">
        <Textarea
          placeholder="Tell AI how to generate the image, the context from stat block will be added to this prompt"
          value={
            studioNodeData.type === StudioNodeType.StatBlock
              ? studioNodeData.generateImage?.prompt ?? ''
              : studioNodeData.generateImage?.prompt ??
                studioNodeData.aiInstructions ??
                ''
          }
          onChange={(e) =>
            props.onStudioNodeDataChange({
              ...studioNodeData!,
              generateImage: {
                ...(studioNodeData.generateImage ?? {}),
                prompt: e.target.value,
              },
            })
          }
        />
      </Field>
      <Field label="Image Negative prompt">
        <Textarea
          placeholder="Tell AI what to avoid when generating this node"
          value={studioNodeData.generateImage?.negativePrompt ?? ''}
          onChange={(e) =>
            props.onStudioNodeDataChange({
              ...studioNodeData!,
              generateImage: {
                ...(studioNodeData.generateImage ?? {}),
                negativePrompt: e.target.value,
              },
            })
          }
        />
      </Field>
      {!!showUniqueOptions && (
        <Field label="Result Uniqueness">
          <Hint>
            Indicate how unique you want the result to be compared to the node
            image.
          </Hint>
          <RadioButtons
            options={uniquenessOptions}
            value={studioNodeData.generateImage?.uniqueness}
            onChange={(selection) =>
              props.onStudioNodeDataChange({
                ...studioNodeData!,
                generateImage: {
                  ...(studioNodeData.generateImage ?? {}),
                  uniqueness: selection,
                },
              })
            }
          />
        </Field>
      )}
    </>
  );
};
