import { ConditionNodeData } from './condition';
import { DiceRollData } from './diceRoll';
import { EpisodeSetupData } from './episodeSetup';
import { GenerateImage } from './generateImage';
import { StatBlockData } from './statBlock';
import { PropertyValue, Value } from './value';

export type BaseStudioNodeData<TType extends StudioNodeType, TData> = TData & {
  id: string;
  type: TType;
  aiInstructions?: string;
  isGenerating?: boolean;
  generateImage?: GenerateImage;
};

export enum StudioNodeType {
  Start = 'Start',
  Comment = 'Comment', // Engine doesn't need to know about this one
  NarratorText = 'NarratorText',
  AiNarration = 'AiNarration',
  SingleSelect = 'SingleSelect',
  CharacterCreation = 'CharacterCreation',
  ObjectiveComplete = 'ObjectiveComplete',
  EndEpisode = 'EndEpisode',
  OnboardingComplete = 'OnboardingComplete',
  CharacterCreatorComplete = 'CharacterCreatorComplete',
  CoinToss = 'CoinToss',
  Image = 'Image',
  LocationUpdate = 'LocationUpdate',
  EpisodeSetup = 'EpisodeSetup',
  ConditionCheck = 'ConditionCheck',
  GetValue = 'GetValue',
  ChangeValue = 'ChangeValue',
  DiceRoll = 'DiceRoll',
  StatBlock = 'StatBlock',
  PlayerInput = 'PlayerInput',
}

export type NarratorTextNodeData = BaseStudioNodeData<
  StudioNodeType.NarratorText,
  {
    text: string;
  }
>;

export type AiNarrationNodeData = BaseStudioNodeData<
  StudioNodeType.AiNarration,
  {
    promptToAi: string;
  }
>;

export type PlayerInputNodeData = BaseStudioNodeData<
  StudioNodeType.PlayerInput,
  {
    prompt: string;
    property: PropertyValue;
    maxLength?: number;
  }
>;

export type CommentNodeData = BaseStudioNodeData<
  StudioNodeType.Comment,
  {
    comment: string;
    arrowPosition?: 'top' | 'right' | 'bottom' | 'left';
  }
>;

export type SingleSelectNodeData = BaseStudioNodeData<
  StudioNodeType.SingleSelect,
  {
    prompt: string;
    options: SingleSelectOption[];
    hideSelectedOptions?: boolean;
    nodeAfterOtherOptionsId?: string;
  }
>;

export type CoinTossNodeData = BaseStudioNodeData<
  StudioNodeType.CoinToss,
  {
    prompt: string;
    property?: Value;
    failNodeId: string;
    successNodeId: string;
  }
>;

export type CharacterCreationNodeData = BaseStudioNodeData<
  StudioNodeType.CharacterCreation,
  object
>;

export type ObjectiveCompleteNodeData = BaseStudioNodeData<
  StudioNodeType.ObjectiveComplete,
  {
    objective: string;
  }
>;

export enum EndEpisodeAction {
  PlayNextEpisode = 'PlayNextEpisode',
  RestartEpisode = 'RestartEpisode',
  RestartSeries = 'RestartSeries',
}

export type EndEpisodeNodeData = BaseStudioNodeData<
  StudioNodeType.EndEpisode,
  {
    message: string;
    action: EndEpisodeAction;
  }
>;

export type ImageNodeData = BaseStudioNodeData<
  StudioNodeType.Image,
  {
    imageUrl: string;
    alt?: string;
    width: number;
    height: number;
  }
>;

export type LocationUpdateNodeData = BaseStudioNodeData<
  StudioNodeType.LocationUpdate,
  {
    locationName: string;
  }
>;

export type StartNodeData = BaseStudioNodeData<
  StudioNodeType.Start,
  {
    newEpisode?: boolean;
  }
>;

export type SingleSelectOption = {
  id: string;
  text: string;
  nextNodeId: string;
};

export type OnboardingCompleteNodeData = BaseStudioNodeData<
  StudioNodeType.OnboardingComplete,
  {
    text: string;
  }
>;

export type CharacterCreatorCompleteNodeData = BaseStudioNodeData<
  StudioNodeType.CharacterCreatorComplete,
  {
    text: string;
  }
>;

export type EpisodeSetupNodeData = BaseStudioNodeData<
  StudioNodeType.EpisodeSetup,
  EpisodeSetupData
>;

export type GetValueNodeData = BaseStudioNodeData<
  StudioNodeType.GetValue,
  {
    value: Value;
    outputName?: string;
  }
>;

export type ConditionCheckNodeData = BaseStudioNodeData<
  StudioNodeType.ConditionCheck,
  ConditionNodeData
>;

export type ChangeValueData = {
  action: 'set' | 'increase' | 'reduce';
  changedValue: Value;
  value?: Value;
  isSilent?: boolean;
};

export type ChangeValueNodeData = BaseStudioNodeData<
  StudioNodeType.ChangeValue,
  ChangeValueData
>;

export type DiceRollNodeData = BaseStudioNodeData<
  StudioNodeType.DiceRoll,
  DiceRollData
>;

export type StatBlockNodeData = BaseStudioNodeData<
  StudioNodeType.StatBlock,
  StatBlockData
>;

export type StudioNodeData =
  | StartNodeData
  | ImageNodeData
  | NarratorTextNodeData
  | AiNarrationNodeData
  | PlayerInputNodeData
  | CommentNodeData
  | SingleSelectNodeData
  | CoinTossNodeData
  | CharacterCreationNodeData
  | ObjectiveCompleteNodeData
  | LocationUpdateNodeData
  | OnboardingCompleteNodeData
  | CharacterCreatorCompleteNodeData
  | EpisodeSetupNodeData
  | ConditionCheckNodeData
  | GetValueNodeData
  | DiceRollNodeData
  | StatBlockNodeData
  | EndEpisodeNodeData
  | ChangeValueNodeData;

export type StudioFlowEdge<TRest = object> = TRest & {
  source: string;
  target: string;
  sourceHandle?: string;
  targetHandle: string;
};

export type StudioFlowNode<TRest = object> = {
  id: string;
  type: StudioNodeType;
  data: StudioNodeData;
} & TRest;

export type StudioFlowState<TNode = object, TEdge = object> = {
  version: 2;
  edges: StudioFlowEdge<TEdge>[];
  nodes: StudioFlowNode<TNode>[];
};

export * from './diceRoll';
export * from './condition';
export * from './value';
export * from './episodeSetup';
export * from './statBlock';
