import { Button, ButtonGroup, Portal } from '@chakra-ui/react';
import {
  ImageInput,
  TextInput,
  FormControl,
  Textarea,
  useMaestroToast,
  Loader,
} from '@maestro/components';
import {
  useStudioEpisodeQuery,
  useUpdateEpisodeMutation,
} from '@maestro/graphql';
import { breakpoints, dimensions } from '@maestro/styles';
import { FC, MutableRefObject, useEffect, useState } from 'react';
import styled from 'styled-components';

type Props = {
  onComplete?: () => void;
  episodeId: string;
  buttonsParentRef: MutableRefObject<HTMLElement | null>;
};

type Fields = Partial<{
  coverImageUrl: string;
  title: string;
  description: string;
}>;

export const EditEpisodeForm: FC<Props> = ({
  buttonsParentRef,
  episodeId,
  onComplete,
}) => {
  const [episode, setEpisode] = useState<Fields>({
    coverImageUrl: '',
    title: '',
    description: '',
  });
  const [submitPressed, setSubmitPressed] = useState(false);
  const {
    data: episodeSourceData,
    isLoading: isLoadingEpisodeData,
    refetch,
  } = useStudioEpisodeQuery(
    { input: { episodeId } },
    {
      // TODO: think of a better path to cache this or make an operation to only fetch the form data
      cacheTime: 0,
      staleTime: 0,
      retry: false,
    },
  );

  const { mutateAsync: updateEpisode, isLoading: isUpdatingEpisode } =
    useUpdateEpisodeMutation();
  const toast = useMaestroToast();

  useEffect(() => {
    if (!!episodeSourceData?.studioEpisode?.data) {
      const { data } = episodeSourceData?.studioEpisode;
      setEpisode({
        coverImageUrl: data?.media?.main ?? '',
        title: data?.title === 'Untitled episode' ? '' : data?.title ?? '',
        description:
          data?.description === 'Episode description here'
            ? ''
            : data?.description ?? '',
      });
    }
  }, [episodeSourceData]);

  const onChange = (key: keyof Fields, value?: string) => {
    setEpisode((state) => ({ ...state, [key]: value }));
  };

  const onSubmit = async () => {
    if (isUpdatingEpisode) {
      return;
    }

    if (!episode.title) {
      setSubmitPressed(true);

      return;
    }

    try {
      const updateResponse = await updateEpisode(
        { input: { id: episodeId, ...episode } },
        {},
      );

      if (updateResponse?.updateEpisode?.error) {
        throw Error(
          'Failed to save episode details, api replied with an error',
        );
      }

      toast({
        title: 'Episode updated!',
        status: 'success',
      });

      await refetch();

      onComplete?.();
    } catch (error: unknown) {
      // TODO: add sentry or logrocket exception handler here
      // eslint-disable-next-line no-console
      console.error(error);
      toast({
        title: 'Oops! it was not possible to save your changes',
        status: 'warning',
      });
    }
  };

  return (
    <>
      {isLoadingEpisodeData && (
        <LoadingWrapper>
          <Loader />
        </LoadingWrapper>
      )}
      <SplitView>
        <Column>
          <FormControl
            label="Episode cover image"
            noMargin
            formControlProps={{
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'flex-start',
              height: '100%',
            }}
          >
            <ImageInput
              value={episode.coverImageUrl}
              uploadPath="episode"
              onChange={(image) => onChange('coverImageUrl', image?.path)}
              insideFlexContainer
              containerAspectRatio={{
                width: dimensions.size300,
                height: dimensions.size200,
              }}
            />
          </FormControl>
        </Column>
        <Column>
          <FormControl
            label="Title"
            isEnabled={submitPressed}
            object={episode}
            field="title"
            errorMessage="Write an episode title"
          >
            <TextInput
              maxCharacters={50}
              value={episode.title}
              onChange={(e) => onChange('title', e.target.value)}
              placeholder="Untitled episode"
            />
          </FormControl>
          <FormControl
            label="Description"
            noMargin
            formControlProps={{
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'flex-start',
              height: '100%',
            }}
          >
            <Textarea
              maxCharacters={1000}
              value={episode.description}
              onChange={(e) => onChange('description', e.target.value)}
              placeholder="Write a short story description for your episode"
            />
          </FormControl>
        </Column>
      </SplitView>
      <Portal containerRef={buttonsParentRef}>
        <ButtonGroup
          display={'flex'}
          flexFlow={'row'}
          justifyContent={'flex-end'}
        >
          <Button
            onClick={onSubmit}
            variant="primary"
            size="md"
            isDisabled={isUpdatingEpisode}
            isLoading={isUpdatingEpisode}
          >
            Save
          </Button>
        </ButtonGroup>
      </Portal>
    </>
  );
};

const SplitView = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size24};
  align-items: flex-start;
  width: 100%;

  @media ${breakpoints.tablet} {
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-flow: column;
  width: calc(100% - ${dimensions.size12});

  @media ${breakpoints.tablet} {
    width: 100%;
  }
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.background.default};
`;
