import type { ObjectiveCompleteNodeData } from '@common/studio-types';
import { memo } from 'react';
import { Position } from 'reactflow';
import { BaseStudioNode } from '../BaseStudioNode';
import { Handle } from '../Handle';
import { StudioNodeComponent } from '../Node.types';

const Node: StudioNodeComponent<ObjectiveCompleteNodeData> = ({
  id,
  data,
  selected,
}) => {
  return (
    <BaseStudioNode
      title="Objective complete"
      selected={selected}
      nodeId={id}
      tag="End"
    >
      {data.objective}
      <Handle
        nodeId={id}
        type="target"
        position={Position.Top}
        id="target-top"
      />
    </BaseStudioNode>
  );
};

export const ObjectiveCompleteNode = memo(Node);
