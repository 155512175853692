import { toOptimizedImageUri } from '@common/image';
import type { ImageNodeData } from '@common/studio-types';
import { Icon } from '@maestro/components/Icon';
import { dimensions, rawDimensions } from '@maestro/styles';
import { memo } from 'react';
import { Position } from 'reactflow';
import styled from 'styled-components';
import { BaseStudioNode } from '../BaseStudioNode';
import { Handle } from '../Handle';
import { StudioNodeComponent } from '../Node.types';

const Node: StudioNodeComponent<ImageNodeData> = ({ id, data, selected }) => {
  const imageProportion = data.width / data.height;
  const height = rawDimensions.size96;

  return (
    <BaseStudioNode
      title="Image"
      selected={selected}
      nodeId={id}
      imagePrompt={data.generateImage?.prompt ?? data.aiInstructions}
      negativePrompt={data.generateImage?.negativePrompt}
    >
      <Handle
        nodeId={data.id}
        type="target"
        position={Position.Top}
        id="target-top"
      />
      <Container>
        {data.imageUrl ? (
          <Image
            src={toOptimizedImageUri(data.imageUrl, {
              width: height * imageProportion,
              height,
              dpr: window.devicePixelRatio,
              resize: 'cover',
            })}
            alt={data.alt}
            $width={height * imageProportion}
            $height={height}
          />
        ) : (
          <EmptyImageContainer>
            <Icon name="image" size={rawDimensions.size24} />
          </EmptyImageContainer>
        )}
      </Container>
      <Handle
        nodeId={data.id}
        type="source"
        position={Position.Bottom}
        id="source-bottom"
      />
    </BaseStudioNode>
  );
};

export const ImageNode = memo(Node);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Image = styled.img<{ $width: number; $height: number }>`
  width: ${({ $width }) => `${$width}px`};
  height: ${({ $height }) => `${$height}px`};
  object-fit: cover;
  border-radius: ${dimensions.size8};
`;

const EmptyImageContainer = styled.div`
  padding: ${dimensions.size28};
  background: ${({ theme }) => theme.colors.background.shade};
  border: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default['100']};
  border-radius: ${dimensions.size8};
`;
