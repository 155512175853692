import type { AiNarrationNodeData } from '@common/studio-types';
import React, { memo } from 'react';
import { NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import { InterpolatedText } from '../../components/value/InterpolatedText';
import { BaseStudioNode } from '../BaseStudioNode';
import { Handle } from '../Handle';

type Props = NodeProps<AiNarrationNodeData>;

const Node: React.FC<Props> = ({ id, data, selected }) => {
  return (
    <BaseStudioNode title="AI Narration" selected={selected} nodeId={id}>
      <Handle
        nodeId={id}
        type="target"
        position={Position.Top}
        id="target-top"
      />
      <Text>
        <InterpolatedText text={data.promptToAi} />
      </Text>
      <Handle
        nodeId={id}
        type="source"
        position={Position.Bottom}
        id="source-bottom"
      />
    </BaseStudioNode>
  );
};

export const AiNarrationNode = memo(Node);

const Text = styled.div`
  white-space: pre-line;
`;
